import React, { useState } from 'react';
import "./register.css";
import { TabSelector } from "./TabSelector";
import { TabPanel, useTabs } from "react-headless-tabs";
import Select from 'react-select';
function RegisterList() {
    const [selectedTab, setSelectedTab] = useTabs(["personal", "family", "horoscope"]);
    const options = [
        { value: 'Self', label: 'Self' },
        { value: 'Parents', label: 'Parents' },
        { value: 'Guardian', label: 'Guardian' },
        { value: 'Friends', label: 'Friends' },
        { value: 'Sibling', label: 'Sibling' },
        { value: 'Relatives', label: 'Relatives' },
    ];
    const gender = [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' }
    ];
    const blood = [
        { value: 'O+ve', label: 'O+ve' },
        { value: 'O-ve', label: 'O-ve' }
    ];
    const education = [
        { value: 'B.Sc', label: 'B.Sc' },
        { value: 'BE', label: 'BE' }
    ];
    const job = [
        { value: 'Government', label: 'Government' },
        { value: 'Private', label: 'Private' }
    ];
    const district = [
        { value: 'Erode', label: 'Erode' },
        { value: 'Coimbatore', label: 'Coimbatore' }
    ];
    const maritalStatus = [
        { value: 'UnMarried', label: 'Un Married' },
        { value: 'Divorce', label: 'Divorce' }
    ];
    const familyTemple = [
        { value: 'Temple1', label: 'Temple 1' },
        { value: 'Temple2', label: 'Temple 2' }
    ];
    const cityData = [
        { value: 'Erode', label: 'Erode' },
        { value: 'Bhavani', label: 'Bhavani' }
    ];
    const stateData = [
        { value: 'Tamilnadu', label: 'Tamilnadu' },
        { value: 'Kerala', label: 'Kerala' }
    ];
    const countryData = [
        { value: 'India', label: 'India' },
        { value: 'America', label: 'America' }
    ];
    const horoscopeData = [
        { value: 'Sevai', label: 'Sevai' },
        { value: 'RaguKethu', label: 'Ragu Kethu' }
    ];
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [emailID, setEmailID] = useState(null);
    const [password, setPassword] = useState(null);
    const [selectedBlood, setSelectedBlood] = useState(null);
    const [selectedEducation, setSelectedEducation] = useState(null);
    const [height, setHeight] = useState(null);
    const [weight, setWeight] = useState(null);
    const [monthlyIncome, setMonthlyIncome] = useState(null);
    const [selectedJobCategory, setsetSelectedJobCategory] = useState(null);
    const [jobDescription, setJobDescription] = useState(null);
    const [jobProof, setJobProof] = useState(null);
    const [jobAddress, setJobAddress] = useState(null);
    const [selectedJobLocation, setSelectedJobLocation] = useState(null);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
    const [divorceCertificate, setDivorceCertificate] = useState(null);
    const [noofChildren, setNoofChildren] = useState(null);
    const [fatherName, setFatherName] = useState(null);
    const [motherName, setMotherName] = useState(null);
    const [fatherJob, setFatherJob] = useState(null);
    const [motherJob, setMotherJob] = useState(null);
    const [selectedFatherTemple, setSelectedFatherTemple] = useState(null);
    const [selectedMotherTemple, setSelectedMotherTemple] = useState(null);
    const [fatherFamilyTemplePlace, setFatherFamilyTemplePlace] = useState(null);
    const [motherFamilyTemplePlace, setMotherFamilyTemplePlace] = useState(null);
    const [familyIncome, setFamilyIncome] = useState(null);
    const [noofBrother, setNoofBrother] = useState(null);
    const [noofSister, setNoofSister] = useState(null);
    const [noofMarriedBrother, setNoofMarriedBrother] = useState(null);
    const [noofMarriedSister, setNoofMarriedSister] = useState(null);
    const [familyAssetsDetails, setFamilyAssetsDetails] = useState(null);
    const [addressLine1, setAddressLine1] = useState(null);
    const [addressLine2, setAddressLine2] = useState(null);
    const [city, setCity] = useState(null);
    const [districtId, setDistrictId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [pinCode, setPinCode] = useState(null);
    const [fatherMobileNo, setFatherMobileNo] = useState(null);
    const [dateofBirth, setDateofBirth] = useState(null);
    const [timeofBirth, setTimeofBirth] = useState(null);
    const [currentAge, setCurrentAge] = useState(null);
    const [placeofBirth, setPlaceofBirth] = useState(null);
    const [typeofHoroscope, setTypeofHoroscope] = useState(null);
    const [birthDistrictId, setBirthDistrictId] = useState(null);
    const [horoscopeCopy, setHoroscopeCopy] = useState(null);
    return (
        <div className='container'>
            <div className="container bg-secondary py-5 hero-header mb-5">
                <div className="row py-5">
                    <div className="col-12 text-center">
                        <h1 className="display-3 text-header animated zoomIn">Register Details</h1>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
                    <div className="gt-panel ">
                        <div className="gt-panel-border-orange">
                            <section className="section_all" id="about">
                                <div className="row vertical_content_manage">
                                    <div className="col-lg-12">
                                        <div className="about_header_main">
                                            <div className="about_icon_box">
                                                <p className="text_custom">Kongu Mangai Matrimony Services</p>
                                                <nav className="flex border-b border-gray-300">
                                                    <TabSelector isActive={selectedTab === "personal"} onClick={() => setSelectedTab("personal")}>Personal Details</TabSelector>
                                                    <TabSelector isActive={selectedTab === "family"} onClick={() => setSelectedTab("family")}>Family Details</TabSelector>
                                                    <TabSelector isActive={selectedTab === "horoscope"} onClick={() => setSelectedTab("horoscope")}>Horoscope Details</TabSelector>
                                                </nav>
                                                <div className="p-4">
                                                    <TabPanel hidden={selectedTab !== "personal"}>
                                                        <div className="row tab-container">
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Profile Created By</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px",
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedOption}
                                                                        placeholder=""
                                                                        onChange={setSelectedOption}
                                                                        options={options} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Gender</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px"
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedGender}
                                                                        placeholder=""
                                                                        onChange={setSelectedGender}
                                                                        options={gender} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>First Name</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setFirstName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            {/* Second Line */}
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Last Name</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setLastName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Mobile Number</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setMobileNumber(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Email ID</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setEmailID(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            {/* Third Line */}
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Password</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setPassword(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Blood Group</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px"
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedBlood}
                                                                        placeholder=""
                                                                        onChange={setSelectedBlood}
                                                                        options={blood} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Education Details</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px"
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedEducation}
                                                                        placeholder=""
                                                                        onChange={setSelectedEducation}
                                                                        options={education} />
                                                                </div>
                                                            </div>
                                                            {/* Fourth Line */}
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Height (Cm)</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setHeight(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Weight (Kg)</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setWeight(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Monthly Income</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setMonthlyIncome(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            {/* Fivth Line */}
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Job Category</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px"
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedJobCategory}
                                                                        placeholder=""
                                                                        onChange={setsetSelectedJobCategory}
                                                                        options={job} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Job Description</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setJobDescription(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Job ID Proof</p>
                                                                    <input type="file"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setJobProof(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            {/* Sixth Line */}
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Job Location</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px"
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedJobLocation}
                                                                        placeholder=""
                                                                        onChange={setSelectedJobLocation}
                                                                        options={district} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Job Address</p>
                                                                    <input type="file"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setJobAddress(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Marital Status</p>
                                                                    <Select
                                                                        styles={{
                                                                            control: (baseStyles) => ({
                                                                                ...baseStyles,
                                                                                borderRadius: "8px",
                                                                                height: "50px"
                                                                            }),
                                                                        }}
                                                                        defaultValue={selectedMaritalStatus}
                                                                        placeholder=""
                                                                        onChange={setSelectedMaritalStatus}
                                                                        options={maritalStatus} />
                                                                </div>
                                                            </div>
                                                            {/* Seventh Line */}
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>Divorce Certificate</p>
                                                                    <input type="file"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setDivorceCertificate(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div>
                                                                    <p className='input-label'>No of Children</p>
                                                                    <input type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        required
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        onChange={e => setNoofChildren(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4"></div>
                                                            <div className="col-lg-4 col-md-4"></div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <button type="submit" className="btn-registersubmit" onClick={() => setSelectedTab("family")}>Next<span></span></button>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel hidden={selectedTab !== "family"}>
                                                        <div>
                                                            <div className="row tab-container">
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Father Name</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFatherName(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Mother Name</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setMotherName(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Father Job</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFatherJob(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Mother Job</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setMotherJob(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Father Family Temple</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={selectedFatherTemple}
                                                                            placeholder=""
                                                                            onChange={setSelectedFatherTemple}
                                                                            options={familyTemple} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Mother Family Temple</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={selectedMotherTemple}
                                                                            placeholder=""
                                                                            onChange={setSelectedMotherTemple}
                                                                            options={familyTemple} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Father Family Temple Place</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFatherFamilyTemplePlace(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Mother Family Temple Place</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setMotherFamilyTemplePlace(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Family Income</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFamilyIncome(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>No of Brothers</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setNoofBrother(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>No of Sisters</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setNoofSister(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>No of Married Brother</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setNoofMarriedBrother(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>No of Married Sister</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setNoofMarriedSister(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Family Assets Details</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFamilyAssetsDetails(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Address Line 1</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setAddressLine1(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Address Line 2</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setAddressLine2(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>City</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={selectedMotherTemple}
                                                                            placeholder=""
                                                                            onChange={setCity}
                                                                            options={cityData} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>State</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={selectedMotherTemple}
                                                                            placeholder=""
                                                                            onChange={setStateId}
                                                                            options={stateData} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Country</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={selectedMotherTemple}
                                                                            placeholder=""
                                                                            onChange={setCountryId}
                                                                            options={countryData} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Pin Code</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setPinCode(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Father Mobile No</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFatherMobileNo(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4"></div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <button type="submit" className="btn-registersubmit" onClick={() => setSelectedTab("horoscope")}>Next<span></span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel hidden={selectedTab !== "horoscope"}>
                                                        <div>
                                                            <div className="row tab-container">
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Date of Birth</p>
                                                                        <input type="date"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setFatherMobileNo(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Time of Birth</p>
                                                                        <input type="time"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setTimeofBirth(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Current Age</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setCurrentAge(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Place of Birth</p>
                                                                        <input type="text"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setPlaceofBirth(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Type of Horoscope</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={typeofHoroscope}
                                                                            placeholder=""
                                                                            onChange={setTypeofHoroscope}
                                                                            options={horoscopeData} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Birth District</p>
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderRadius: "8px",
                                                                                    height: "50px"
                                                                                }),
                                                                            }}
                                                                            defaultValue={birthDistrictId}
                                                                            placeholder=""
                                                                            onChange={setBirthDistrictId}
                                                                            options={district} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div>
                                                                        <p className='input-label'>Horoscope Copy</p>
                                                                        <input type="file"
                                                                            name="Name"
                                                                            id="Name"
                                                                            required
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={e => setHoroscopeCopy(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4"></div>
                                                                <div className="col-lg-4 col-md-4"></div>
                                                                <div className="col-lg-4 col-md-4"></div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <button type="submit" className="btn-registersubmit" onClick={() => { }}>Submit<span></span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RegisterList;