import React from 'react';
import "./memberdashboard.css";

function Memberdashboard() {
  return (
    <div className='container dashboardView'>
      <section class="py-5 bg-white">
        <div class="container">
          <div class="d-flex align-items-start">
            <div class="aiz-user-sidenav-wrap pt-4 sticky-top c-scrollbar-light position-relative z-1 shadow-none">
              <div class="absolute-top-left d-xl-none">
                <button class="btn btn-sm p-2" data-toggle="class-toggle" data-target=".aiz-mobile-side-nav"
                  data-same=".mobile-side-nav-thumb">
                  <i class="las la-times la-2x"></i>
                </button>
              </div>
              <div class="aiz-user-sidenav rounded overflow-hidden">
                <div class="px-4 text-center mb-4">
                  <span class="avatar avatar-md mb-3">
                    {/* <img src="https://demo.activeitzone.com/matrimonial/public/uploads/all/uVl6pf6oqBpZiJuai4iwU4KCRAGe9plsh5wDrnkN.png" alt=""/> */}
                  </span>
                  <h4 class="h5 fw-600">Nandhakumar</h4>
                </div>
                <div class="text-center mb-3 px-3">
                  <p>Public Profile</p>
                </div>

                <div class="sidemnenu mb-3">
                  <ul class="aiz-side-nav-list" data-toggle="aiz-side-menu">
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link active">
                        <i class="las la-home aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Dashboard</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/login" class="aiz-side-nav-link">
                        <i class="las la-image aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Gallery</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-handshake aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Happy Story</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-shopping-basket aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Packages</span>
                        <span class="aiz-side-nav-arrow"></span>
                      </a>
                      <ul class="aiz-side-nav-list level-2">
                        <li class="aiz-side-nav-item">
                          <a href="/" class="aiz-side-nav-link">
                            <span class="aiz-side-nav-text">Packages</span>
                          </a>
                        </li>
                        <li class="aiz-side-nav-item">
                          <a href="/" class="aiz-side-nav-link">
                            <span class="aiz-side-nav-text">Package Purchase History</span>
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-envelope aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Message</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/"
                        class="aiz-side-nav-link ">
                        <i class="las la-life-ring aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Support Ticket</span>

                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="la la-heart-o aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">My Interest</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-list aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Shortlist</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-ban aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Ignored User List</span>
                      </a>
                    </li>

                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-key aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Change Password</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link">
                        <i class="las la-user aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Manage Profile</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link" onclick="account_deactivation()">
                        <i class="las la-lock aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Deactive Account</span>
                      </a>
                    </li>
                    <li class="aiz-side-nav-item">
                      <a href="/" class="aiz-side-nav-link" onclick="account_delete()">
                        <i class="las la-trash aiz-side-nav-icon"></i>
                        <span class="aiz-side-nav-text">Delete Account</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                </div>
              </div>
            </div>

            <div class="aiz-user-panel overflow-hidden">
              <div class="row gutters-5">
                <div class="col-md-6">

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Memberdashboard;