import React from 'react';
import "./components.css";
import Logo from "../../src/assets/mangai_logo.png";

function header() {
    return (
        <div>
            <header className="header">
                <div className="container">
                    <a href="/"><img src={Logo} alt="logo" className="logo" /></a>
                    <input className="menu-btn" type="checkbox" id="menu-btn" />
                    <label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
                    <ul className="menu">
                        <li><a href="/" className="active">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/members">Members</a></li>
                        <li><a href="/search">Search</a></li>
                        <li><a href="/plans">Plans</a></li>
                        <li><a href="/story">Happy Story</a></li>
                        <li><a href="/contactus">Contact Us</a></li>
                    </ul>
                </div>
            </header>
        </div>
    )
}

export default header