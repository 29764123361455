import React from 'react';
import "./home.css";
import Banner from "../../assets/banner-1.jpg";
function Home() {
  return (
    <div className='container'>
      <div>
        <img className="bannerview" src={Banner} alt="banner" />
      </div>

      <div className="container boxview">
        <p className='headerTitle'>Horoscope List</p>
      </div>

      <div className="container boxview">
        <div className="row">
          <div className="col-sm">
            <div className="card cardstyle">
              <div className="card-body">
                <p className="card-title">Ragu Kethu Jathagam</p>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="btn btn-danger btnview">Total Count : 120</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card cardstyle">
              <div className="card-body">
                <h5 className="card-title">Ragu Kethu Sevai</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="btn btn-danger btnview">Total Count : 120</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card cardstyle">
              <div className="card-body">
                <h5 className="card-title">Sevai Jathagam</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="btn btn-danger btnview">Total Count : 120</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="card cardstyle">
              <div className="card-body">
                <h5 className="card-title">Sutha Jathagam</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="btn btn-danger btnview">Total Count : 120</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card cardstyle">
              <div className="card-body">
                <h5 className="card-title">Doctor Jathagam</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="btn btn-danger btnview">Total Count : 120</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card cardstyle">
              <div className="card-body">
                <h5 className="card-title">Second Marriage</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="btn btn-danger btnview">Total Count : 120</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
            <div className="gt-panel ">
              <div className="gt-panel-border-orange">
                <p className='headerTitle'>How it Work</p>
              </div>
              <div className="gt-panel-body">
                <div className="row">
                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="workcard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                              <img src={Banner} alt="" className="workcardimage" />
                            </div>
                            <div className="media-body text-right">
                              <h3 className='workStepHeader'>1</h3>
                              <span className='workHeader'>Register</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="workcard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                              <img src={Banner} alt="" className="workcardimage" />
                            </div>
                            <div className="media-body text-right">
                              <h3 className='workStepHeader'>2</h3>
                              <span className='workHeader'>Approval</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="workcard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                              <img src={Banner} alt="" className="workcardimage" />
                            </div>
                            <div className="media-body text-right">
                              <h3 className='workStepHeader'>3</h3>
                              <span className='workHeader'>Connect</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="workcard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="media d-flex">
                            <div className="align-self-center">
                              <img src={Banner} alt="" className="workcardimage" />
                            </div>
                            <div className="media-body text-right">
                              <h3 className='workStepHeader'>4</h3>
                              <span className='workHeader'>Interact</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container boxview">
        <p className='headerTitle'>Testimonial</p>
      </div>
      <div class="testimonials text-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="card border-light bg-light text-center">
                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                <div class="card-body blockquote">
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                  <footer class="card-title"><cite title="card-title">Mohamed Nady</cite></footer>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="card border-light bg-light text-center">
                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                <div class="card-body blockquote">
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                  <footer class="card-title"><cite title="card-title">Mohamed Nady</cite></footer>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="card border-light bg-light text-center">
                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                <div class="card-body blockquote">
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                  <footer class="card-title"><cite title="card-title">Mohamed Nady</cite></footer>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="card border-light bg-light text-center">
                <i class="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                <div class="card-body blockquote">
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum enim eu nibh finibus</p>
                  <footer class="card-title"><cite title="card-title">Mohamed Nady</cite></footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Home;