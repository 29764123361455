import React from 'react';
import "./refundpolicy.css";

function RefundPolicy() {
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">Refund Policy</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange">
              <section className="section_all" id="about">
                <div className="row vertical_content_manage">
                  <div className="col-lg-12">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>
                        <p className="text_paragraph">In the event you terminate your membership, you will not be entitled to a refund of any subscription fees, if any, paid by you.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default RefundPolicy;