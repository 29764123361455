import React, { useState } from 'react';
import "./register.css";
import Banner from "../../assets/banner-1.jpg";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
function Register() {
  let navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  var errorMsg = "";
  const registerFunction = () => {
    let path = `/registerotp`;
    if (userName === null) {
      errorMsg = "User Name Missing";
      showSwal();
    } else if (mobileNumber === null) {
      errorMsg = "User Mobile Number Missing";
      showSwal();
    } else if (mobileNumber.length <= 9) {
      errorMsg = "You have to enter at 10 digit Mobile Number!";
      showSwal();
    } else if (userName !== null && mobileNumber.length === 10) {
      navigate(path);
    }
  }
  const showSwal = () => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      title: 'Input Missing!',
      text: errorMsg,
      type: 'warning',
      showCancelButton: 0,
      confirmButtonText: 'OK',
    });
  }
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">Register</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange">
              <section className="section_all" id="about">
                <div className="row vertical_content_manage">
                  <div className="col-lg-12">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>

                        <section className='sections'>
                          <div class="container">
                            <div class="user signinBx">
                              <div class="imgBx"><img src={Banner} alt="" /></div>
                              <div class="formBx">
                                <div>
                                  <h2 className='text_custom'>Register</h2>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <input type="text"
                                        name="Name"
                                        id="Name"
                                        required
                                        className="form-control"
                                        placeholder="Name"
                                        onChange={e => setUserName(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <input type="text"
                                        name="MobileNumber" id="MobileNumber"
                                        className="form-control"
                                        required
                                        maxlength="10" max="10" min="10"
                                        placeholder="10 Digit Mobile Number"
                                        onChange={e => setMobileNumber(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <p className="register-text">Already have an account ? <a href="/login">Login.</a></p>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <button type="submit" className="btn-submit" onClick={() => registerFunction()}>Register Now<span></span></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Register;