import React from 'react';
import "./about.css";
import Banner from "../../assets/banner-2.jpg";

function About() {
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">About Us</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange ">
              <section className="section_all container" id="about">
                <div className="row vertical_content_manage mt-3">
                  <div className="col-lg-6">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>
                      </div>
                      <p className="text-paragraph mt-3">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                      <p className="text-paragraph mt-3"> Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                      <p className="text-paragraph mt-3">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="img_about mt-3">
                      <img src={Banner}  alt="" class="img-fluid mx-auto d-block" />
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-4">
                    <div class="about_content_box_all mt-3">
                      <div class="about_detail text-center">
                        <div class="about_icon">
                          <i class="fas fa-pencil-alt"></i>
                        </div>
                        <h5 class="text-capitalize mt-3">Creative Design</h5>
                        <p class="edu_desc mt-3 mb-0 text-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="about_content_box_all mt-3">
                      <div class="about_detail text-center">
                        <div class="about_icon">
                          <i class="fab fa-angellist"></i>
                        </div>
                        <h5 class="text-capitalize mt-3">We make Best Result</h5>
                        <p class="edu_desc mb-0 mt-3 text-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="about_content_box_all mt-3">
                      <div class="about_detail text-center">
                        <div class="about_icon">
                          <i class="fas fa-paper-plane"></i>
                        </div>
                        <h5 class="text-capitalize mt-3">best platform </h5>
                        <p class="edu_desc mb-0 mt-3 text-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default About;