import React, { useState } from 'react';
import "./register.css";
import Banner from "../../assets/banner-1.jpg";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
function RegisterOtp() {
    let navigate = useNavigate();
    const [otp, setOtp] = useState(null);
    var errorMsg = "";
    const otpFunction = () => {
        let path = `/registerlist`;
        if (otp === null) {
            errorMsg = "OTP Missing";
            showSwal();
        } else if (otp.length <= 5) {
            errorMsg = "You have to enter at 6 digit OTP!";
            showSwal();
        } else if (otp !== null && otp.length === 6) {
            navigate(path);
        }
    }
    const showSwal = () => {
        Swal.fire({
            allowOutsideClick: false,
            icon: "warning",
            title: 'Input Missing!',
            text: errorMsg,
            type: 'warning',
            showCancelButton: 0,
            confirmButtonText: 'OK',
        });
    }
    return (
        <div className='container'>
            <div className="container bg-secondary py-5 hero-header mb-5">
                <div className="row py-5">
                    <div className="col-12 text-center">
                        <h1 className="display-3 text-header animated zoomIn">OTP Verification</h1>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
                    <div className="gt-panel ">
                        <div className="gt-panel-border-orange">
                            <section className="section_all" id="about">
                                <div className="row vertical_content_manage">
                                    <div className="col-lg-12">
                                        <div className="about_header_main">
                                            <div className="about_icon_box">
                                                <p className="text_custom">Kongu Mangai Matrimony Services</p>

                                                <section className='sections'>
                                                    <div class="container">
                                                        <div class="user signinBx">
                                                            <div class="imgBx"><img src={Banner} alt="" /></div>
                                                            <div class="formBx">
                                                                <div>
                                                                    <h2 className='text_custom'>OTP Verification</h2>
                                                                    {/* <input type="text" className="text_paragraph" name="" placeholder="OTP number" />
                                                                    <input type="submit" name="" value="Verify Now" onClick={() => otpFunction()} /> */}
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <input type="text"
                                                                                name="otp"
                                                                                id="otp"
                                                                                maxlength="6" max="6" min="6"
                                                                                required
                                                                                className="form-control"
                                                                                placeholder="OTP"
                                                                                onChange={e => setOtp(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <button type="submit" className="btn-submit" onClick={() => otpFunction()}>Verify Now<span></span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RegisterOtp;