import React, { useState } from 'react';
import "./login.css";
import { useNavigate } from "react-router-dom";
import Banner from "../../assets/banner-1.jpg";
import Swal from 'sweetalert2';
function Login() {
  let navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  var errorMsg = "";
  const loginFunction = () => {
    let path = `/otp`;
    if (userName === null) {
      errorMsg = "User Name Missing";
      showSwal();
    } else if (userPassword === null) {
      errorMsg = "User Password Missing";
      showSwal();
    } else if (userPassword.length <= 5) {
      errorMsg = "You have to enter at least 6 digit!";
      showSwal();
    } else if (userName !== null && userPassword !== null) {
      navigate(path);
    }
  }
  const showSwal = () => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      title: 'Input Missing!',
      text: errorMsg,
      type: 'warning',
      showCancelButton: 0,
      confirmButtonText: 'OK',
    });
  }
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">Login</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange">
              <section className="section_all" id="about">
                <div className="row vertical_content_manage">
                  <div className="col-lg-12">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>

                        <section className='sections'>
                          <div class="container">
                            <div class="user signinBx">
                              <div class="imgBx"><img src={Banner} alt="" /></div>
                              <div class="formBx">
                                <div>
                                  <h2 className='text_custom'>Login</h2>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <input type="text"
                                        name="Username"
                                        id="Username"
                                        required
                                        className="form-control"
                                        placeholder="Username"
                                        onChange={e => setUserName(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <input type="password"
                                        name="Password" id="Password"
                                        className="form-control"
                                        required
                                        placeholder="Password"
                                        onChange={e => setUserPassword(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <button type="submit" className="btn-submit" onClick={() => loginFunction()}>Login<span></span></button>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <p className="forgotpassword-text"><a className="forgotpassword-text" href="/forgotpassword">Forgot Password</a></p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <p className="register-text">Don't have an account ? <a href="/register">Register Here.</a></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;