import React from 'react';
import "./plans.css";

function Plans() {
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">Plans</h1>
          </div>
        </div>
      </div>
      <div id="price">
        <div className="row">
          <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
            <div className="gt-panel ">
              <div className="gt-panel-border-orange">
                <section className="section_all" id="about">
                  <div className="row vertical_content_manage">
                    <div className="col-lg-12">
                      <div className="about_header_main">
                        <div className="about_icon_box">
                          <div className="plan">
                            <div className="plan-inner">
                              <div className="entry-title">
                                <h3>Basic Plan</h3>
                                <div className="price">750<span>+ 18% GST</span>
                                </div>
                              </div>
                              <div className="entry-content">
                                <ul>
                                  <li>Option 1</li>
                                  <li>Option 2</li>
                                  <li>Option 3</li>
                                  <li>Option 4</li>
                                  <li>Option 5</li>
                                </ul>
                              </div>
                              <div className="btn">
                                <a href="/">Buy Now</a>
                              </div>
                            </div>
                          </div>
                          <div className="plan basic">
                            <div className="plan-inner">
                              {/* <div className="hot">hot</div> */}
                              <div className="entry-title">
                                <h3>Express Plan</h3>
                                <div className="price">1000<span> + 18% GST</span>
                                </div>
                              </div>
                              <div className="entry-content">
                                <ul>
                                  <li>Option 1</li>
                                  <li>Option 2</li>
                                  <li>Option 3</li>
                                  <li>Option 4</li>
                                  <li>Option 5</li>
                                </ul>
                              </div>
                              <div className="btn">
                                <a href="/">Buy Now</a>
                              </div>
                            </div>
                          </div>
                          <div className="plan standard">
                            <div className="plan-inner">
                              <div className="entry-title">
                                <h3>Sliver Plan</h3>
                                <div className="price">1250<span> + 18% GST</span>
                                </div>
                              </div>
                              <div className="entry-content">
                                <ul>
                                  <li>Option 1</li>
                                  <li>Option 2</li>
                                  <li>Option 3</li>
                                  <li>Option 4</li>
                                  <li>Option 5</li>
                                </ul>
                              </div>
                              <div className="btn">
                                <a href="/">Buy Now</a>
                              </div>
                            </div>
                          </div>
                          <div className="plan ultimite">
                            <div className="plan-inner">
                              <div className="entry-title">
                                <h3>Gold Plan</h3>
                                <div className="price">2000<span> + 18% GST</span>
                                </div>
                              </div>
                              <div className="entry-content">
                                <ul>
                                  <li>Option 1</li>
                                  <li>Option 2</li>
                                  <li>Option 3</li>
                                  <li>Option 4</li>
                                  <li>Option 5</li>
                                </ul>
                              </div>
                              <div className="btn">
                                <a href="/">Buy Now</a>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plans;