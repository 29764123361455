import React from 'react';
import "./faq.css";

function Faq() {

  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">FAQ</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange">
              <section className="section_all" id="about">
                <div className="row vertical_content_manage">
                  <div className="col-lg-12">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>
                        <div class="faq-articles">
                          <article class="faq-accordion">
                            <input type="checkbox" class="tgg-title" id="tgg-title-1" />
                            <div class="faq-accordion-title">
                              <label for="tgg-title-1">
                                <p className='question_header'>How many team members can I invite?</p>
                                <span class="arrow-icon">
                                  <img src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg" alt='kongu'/>
                                </span>
                              </label>
                            </div>
                            <div class="faq-accordion-content">
                              <p className='text-paragraph'>You can invite up to 2 additional users on the Free plan. There is no limit on
                                team members for the Premium plan.</p>
                            </div>
                          </article>
                          <article class="faq-accordion">
                            <input type="checkbox" class="tgg-title" id="tgg-title-2" />
                            <div class="faq-accordion-title">
                              <label for="tgg-title-2">
                                <p className='question_header'> What is the maximum file upload size?</p>
                                <span class="arrow-icon">
                                  <img src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg" alt='kongu'/>
                                </span>
                              </label>
                            </div>
                            <div class="faq-accordion-content">
                              <p className='text-paragraph'>No more than 2GB. All files in your account must fit your allotted storage space.</p>
                            </div>
                          </article>
                          <article class="faq-accordion">
                            <input class="tgg-title" type="checkbox" id="tgg-title-3" />
                            <div class="faq-accordion-title">
                              <label for="tgg-title-3">
                                <p className='question_header'>How do I reset my password?</p>
                                <span class="arrow-icon">
                                  <img src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg" alt='kongu'/>
                                </span>
                              </label>
                            </div>
                            <div class="faq-accordion-content">
                              <p className='text-paragraph'>Click “Forgot password” from the login page or “Change password” from your profile page.
                                A reset link will be emailed to you.</p>
                            </div>
                          </article>
                          <article class="faq-accordion">
                            <input class="tgg-title" type="checkbox" id="tgg-title-4" />
                            <div class="faq-accordion-title">
                              <label for="tgg-title-4">
                                <p className='question_header'> Can I cancel my subscription? </p>
                                <span class="arrow-icon">
                                  <img src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg" alt='kongu'/>
                                </span>
                              </label>
                            </div>
                            <div class="faq-accordion-content">
                              <p className='text-paragraph'>Yes! Send us a message and we’ll process your request no questions asked.</p>
                            </div>
                          </article>
                          <article class="faq-accordion">
                            <input class="tgg-title" type="checkbox" id="tgg-title-5" />
                            <div class="faq-accordion-title">
                              <label for="tgg-title-5">
                                <p className='question_header'>Do you provide additional support?</p>
                                <span class="arrow-icon">
                                  <img src="https://raw.githubusercontent.com/Romerof/FAQ-accordion-card/main/images/icon-arrow-down.svg" alt='kongu'/>
                                </span>
                              </label>
                            </div>
                            <div class="faq-accordion-content">
                              <p className='text-paragraph'> Chat and email support is available 24/7. Phone lines are open during normal business hours.</p>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Faq;