import React from 'react';
import "./story.css";
import Banner from "../../assets/banner-1.jpg";

function story() {
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">Happy Story</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange">
              <section className="section_all" id="about">
                <div className="row vertical_content_manage">
                  <div className="col-lg-12">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>
                        <div class="wrapper">
                          <div class="cards">
                            <div class="cards-banner">
                              <p class="category-tag popular">Doctor</p>
                              <img class="banner-img" src={Banner} alt='' />
                            </div>
                            <div class="cards-body">
                              <p className='story-title'>Story - Title</p>
                              <p class="blog-description">Found my soulmate with Kongu Mangai Matrimony help! I was looking for a caring and loving life partner. I came across N kailash profile and started talking to N kailash . After a few calls, we found that we had similar interests and were highly compatible with each other. I thank Kongu Mangai Matrimony for helping me find my life partner</p>
                              <div class="cards-profile">
                                <img class="profile-img" src='https://marketplace.canva.com/EAFxwV1WnyE/1/0/1600w/canva-black-and-gold-elegant-facebook-profile-picture-pRHweav1H0c.jpg' alt='' />
                                <div class="cards-profile-info">
                                  <h3 class="profile-name">Kanmani</h3>
                                  <p class="profile-followers">Posted On 25 Apr 2024</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cards">
                            <div class="cards-banner">
                              <p class="category-tag popular">Doctor</p>
                              <img class="banner-img" src={Banner} alt='' />
                            </div>
                            <div class="cards-body">
                            <p className='story-title'>Story - Title</p>
                              <p class="blog-description">Found my soulmate with Kongu Mangai Matrimony help! I was looking for a caring and loving life partner. I came across N kailash profile and started talking to N kailash . After a few calls, we found that we had similar interests and were highly compatible with each other. I thank Kongu Mangai Matrimony for helping me find my life partner</p>
                              <div class="cards-profile">
                                <img class="profile-img" src='https://marketplace.canva.com/EAFxwV1WnyE/1/0/1600w/canva-black-and-gold-elegant-facebook-profile-picture-pRHweav1H0c.jpg' alt='' />
                                <div class="cards-profile-info">
                                  <h3 class="profile-name">Kanmani</h3>
                                  <p class="profile-followers">Posted On 25 Apr 2024</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cards">
                            <div class="cards-banner">
                              <p class="category-tag popular">Doctor</p>
                              <img class="banner-img" src={Banner} alt='' />
                            </div>
                            <div class="cards-body">
                            <p className='story-title'>Story - Title</p>
                              <p class="blog-description">Found my soulmate with Kongu Mangai Matrimony help! I was looking for a caring and loving life partner. I came across N kailash profile and started talking to N kailash . After a few calls, we found that we had similar interests and were highly compatible with each other. I thank Kongu Mangai Matrimony for helping me find my life partner</p>
                              <div class="cards-profile">
                                <img class="profile-img" src='https://marketplace.canva.com/EAFxwV1WnyE/1/0/1600w/canva-black-and-gold-elegant-facebook-profile-picture-pRHweav1H0c.jpg' alt='' />
                                <div class="cards-profile-info">
                                  <h3 class="profile-name">Kanmani</h3>
                                  <p class="profile-followers">Posted On 25 Apr 2024</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cards">
                            <div class="cards-banner">
                              <p class="category-tag popular">Doctor</p>
                              <img class="banner-img" src={Banner} alt='' />
                            </div>
                            <div class="cards-body">
                            <p className='story-title'>Story - Title</p>
                              <p class="blog-description">Found my soulmate with Kongu Mangai Matrimony help! I was looking for a caring and loving life partner. I came across N kailash profile and started talking to N kailash . After a few calls, we found that we had similar interests and were highly compatible with each other. I thank Kongu Mangai Matrimony for helping me find my life partner</p>
                              <div class="cards-profile">
                                <img class="profile-img" src='https://marketplace.canva.com/EAFxwV1WnyE/1/0/1600w/canva-black-and-gold-elegant-facebook-profile-picture-pRHweav1H0c.jpg' alt='' />
                                <div class="cards-profile-info">
                                  <h3 class="profile-name">Kanmani</h3>
                                  <p class="profile-followers">Posted On 25 Apr 2024</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cards">
                            <div class="cards-banner">
                              <p class="category-tag popular">Doctor</p>
                              <img class="banner-img" src={Banner} alt=''/>
                            </div>
                            <div class="cards-body">
                            <p className='story-title'>Story - Title</p>
                              <p class="blog-description">Found my soulmate with Kongu Mangai Matrimony help! I was looking for a caring and loving life partner. I came across N kailash profile and started talking to N kailash . After a few calls, we found that we had similar interests and were highly compatible with each other. I thank Kongu Mangai Matrimony for helping me find my life partner</p>
                              <div class="cards-profile">
                                <img class="profile-img" src='https://marketplace.canva.com/EAFxwV1WnyE/1/0/1600w/canva-black-and-gold-elegant-facebook-profile-picture-pRHweav1H0c.jpg' alt='' />
                                <div class="cards-profile-info">
                                  <h3 class="profile-name">Kanmani</h3>
                                  <p class="profile-followers">Posted On 25 Apr 2024</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="cards">
                            <div class="cards-banner">
                              <p class="category-tag popular">Doctor</p>
                              <img class="banner-img" src={Banner} alt='' />
                            </div>
                            <div class="cards-body">
                            <p className='story-title'>Story - Title</p>
                              <p class="blog-description">Found my soulmate with Kongu Mangai Matrimony help! I was looking for a caring and loving life partner. I came across N kailash profile and started talking to N kailash . After a few calls, we found that we had similar interests and were highly compatible with each other. I thank Kongu Mangai Matrimony for helping me find my life partner</p>
                              <div class="cards-profile">
                                <img class="profile-img" src='https://marketplace.canva.com/EAFxwV1WnyE/1/0/1600w/canva-black-and-gold-elegant-facebook-profile-picture-pRHweav1H0c.jpg' alt='' />
                                <div class="cards-profile-info">
                                  <h3 class="profile-name">Kanmani</h3>
                                  <p class="profile-followers">Posted On 25 Apr 2024</p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default story