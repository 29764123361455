import React from 'react';
import "./components.css";

function footer() {
  return (
    <div>
      <footer class="section bg-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="">
                <h6 class="footer-heading text-uppercase text-white">Help And Support</h6>
                <ul class="list-unstyled footer-link mt-4">
                  <li><a href="/about">Contact Us</a></li>
                  <li><a href="/faq">FAQ</a></li>
                  <li><a href="/refundpolicy">Refund Policy</a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="">
                <h6 class="footer-heading text-uppercase text-white">Terms & Policy</h6>
                <ul class="list-unstyled footer-link mt-4">
                  <li><a href="/termsandcondition">Terms & Condition </a></li>
                  <li><a href="/privacypolicy">Privacy Policy</a></li>
                  <li><a href="/report">Report Misuse</a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2">
              <div class="">
                <h6 class="footer-heading text-uppercase text-white">Need Help?</h6>
                <ul class="list-unstyled footer-link mt-4">
                  <li><a href="/login">Login </a></li>
                  <li><a href="/register">Register</a></li>
                  <li><a href="/plans">Plans</a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="">
                <h6 class="footer-heading text-uppercase text-white">Contact Us</h6>
                <p class="contact-info mt-4">Contact us if need help with anything</p>
                <p class="contact-info mt-4">+91 93849 00177</p>
              </div>
            </div >
          </div >
        </div>
        <div class="text-center mt-5">
          <p class="footer-alt mb-0 f-14 footer-text">2024 © Kongu Mangai Matrimony, All Rights Reserved. Designed By <a className="footer-text" href="https://praneeshinfotech.com" target="_blank" rel="noopener noreferrer">Praneesh Infotech - Ayyampalayam.</a></p>
        </div>
      </footer >
    </div >
  )
}

export default footer