import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from "../src/pages/home/home";
import About from "../src/pages/about/about";
import Contactus from "../src/pages/contactus/contactus";
import Members from "../src/pages/members/members";
import Plans from "../src/pages/plans/plans";
import Story from "../src/pages/story/story";
import Register from "../src/pages/register/register";
import Login from "../src/pages/login/login";
import Report from "../src/pages/report/report";
import PrivacyPolicy from "../src/pages/privacy/privacypolicy";
import TermsCondition from "../src/pages/termscondition/termscondition";
import Faq from "../src/pages/faq/faq";
import RefundPolicy from "../src/pages/refundpolicy/refundpolicy";
import Memberdashboard from './pages/members/memberdashboard';
import Otp from './pages/otp/otp';
import RegisterOtp from './pages/register/registerotp';
import RegisterList from './pages/register/registerlist';
import ForgotPassword from './pages/forgotpassword/forgotpassword';
import Search from "./pages/search/search";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/members" element={<Members />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/story" element={<Story />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/report" element={<Report />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandcondition" element={<TermsCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/memberdashboard" element={<Memberdashboard />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/registerotp" element={<RegisterOtp />} />
          <Route path="/registerlist" element={<RegisterList />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/search" element={<Search />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
