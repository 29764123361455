import React, { useState } from 'react';
import "./search.css";
import Select from 'react-select';
function Search() {
    const [minAge, setMinAge] = useState("");
    const [maxAge, setMaxAge] = useState("");
    const [selectedEducation, setSelectedEducation] = useState(null);
    if (minAge === maxAge) {
        console.log("Alert");
    }
    const education = [
        { value: 'B.Sc', label: 'B.Sc' },
        { value: 'BE', label: 'BE' }
    ];
    return (
        <div className='container'>
            <div className="container bg-secondary py-5 hero-header mb-5">
                <div className="row py-5">
                    <div className="col-12 text-center">
                        <h1 className="display-3 text-header animated zoomIn">Search</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
                    <div className="gt-panel ">
                        <div className="gt-panel-border-orange">
                            <section className="section_all" id="about">
                                <div className="row vertical_content_manage mt-3">
                                    <div className="col-lg-12">
                                        <div className="about_header_main">
                                            <div className="container">
                                                <div className='filter_container'>
                                                    <div className="row tab-container">
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Minimum Age</p>
                                                                <input type="text"
                                                                    name="minAge"
                                                                    id="minAge"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={e => setMinAge(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Maximum Age</p>
                                                                <input type="text"
                                                                    name="minAge"
                                                                    id="minAge"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={e => setMaxAge(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Minimum Height</p>
                                                                <input type="text"
                                                                    name="minAge"
                                                                    id="minAge"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={e => setMinAge(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Maximum Height</p>
                                                                <input type="text"
                                                                    name="minAge"
                                                                    id="minAge"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={e => setMaxAge(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Member ID</p>
                                                                <input type="text"
                                                                    name="minAge"
                                                                    id="minAge"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={e => setMaxAge(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <p className='input-label'></p>
                                                            <button type="submit" className="btn-registersubmit" /* onClick={() => setSelectedTab("family")} */>Search<span></span></button>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Education Details</p>
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: "8px",
                                                                            height: "50px"
                                                                        }),
                                                                    }}
                                                                    defaultValue={selectedEducation}
                                                                    placeholder=""
                                                                    onChange={setSelectedEducation}
                                                                    options={education} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Education Details</p>
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: "8px",
                                                                            height: "50px"
                                                                        }),
                                                                    }}
                                                                    defaultValue={selectedEducation}
                                                                    placeholder=""
                                                                    onChange={setSelectedEducation}
                                                                    options={education} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Education Details</p>
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: "8px",
                                                                            height: "50px"
                                                                        }),
                                                                    }}
                                                                    defaultValue={selectedEducation}
                                                                    placeholder=""
                                                                    onChange={setSelectedEducation}
                                                                    options={education} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Education Details</p>
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: "8px",
                                                                            height: "50px"
                                                                        }),
                                                                    }}
                                                                    defaultValue={selectedEducation}
                                                                    placeholder=""
                                                                    onChange={setSelectedEducation}
                                                                    options={education} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Education Details</p>
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: "8px",
                                                                            height: "50px"
                                                                        }),
                                                                    }}
                                                                    defaultValue={selectedEducation}
                                                                    placeholder=""
                                                                    onChange={setSelectedEducation}
                                                                    options={education} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div>
                                                                <p className='input-label'>Education Details</p>
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderRadius: "8px",
                                                                            height: "50px"
                                                                        }),
                                                                    }}
                                                                    defaultValue={selectedEducation}
                                                                    placeholder=""
                                                                    onChange={setSelectedEducation}
                                                                    options={education} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row list-tab-container">
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table class="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row list-tab-container">
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table class="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row list-tab-container">
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table class="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row list-tab-container">
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table class="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="product-card">
                                                <div className="badge">Sevvai</div>
                                                <div className="product-tumb">
                                                    <img src="https://images.malayogamonline.com//images/seo_bg_couple.jpg" alt="" />
                                                </div>
                                                <div className="product-details">
                                                    <p className="product-id-text">KM20240001</p>
                                                    <p className="product-text">Radhai</p>
                                                </div>
                                                <table className="content-table">
                                                    <tbody>
                                                        <tr>
                                                            <td className='list_text'>Koottam</td>
                                                            <td className='list_text'>Thooran</td>
                                                        </tr>
                                                        <tr class="active-row">
                                                            <td className='list_text'>Height</td>
                                                            <td className='list_text'>5ft 4in</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Age</td>
                                                            <td className='list_text'>26</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='list_text'>Occupation</td>
                                                            <td className='list_text'>Doctor</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Search