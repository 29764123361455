import React, { useState } from 'react';
import "./contactus.css";
import Swal from 'sweetalert2';
function Contactus() {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  var errorMsg = "";
  const contactFunction = () => {
    if (name === null) {
      errorMsg = "User Name Missing";
      showSwal();
    } else if (email === null) {
      errorMsg = "User Email ID Missing";
      showSwal();
    } else if (mobileNumber === null) {
      errorMsg = "Mobile Number Missing";
      showSwal();
    } else if (mobileNumber.length <= 9) {
      errorMsg = "Enter the 10 Digit Mobile Number";
      showSwal();
    } else if (subject === null) {
      errorMsg = "Subject Missing";
      showSwal();
    } else if (message === null) {
      errorMsg = "Message Missing";
      showSwal();
    } else {

    }
  }
  const showSwal = () => {
    Swal.fire({
      allowOutsideClick: false,
      icon: "warning",
      title: 'Input Missing!',
      text: errorMsg,
      type: 'warning',
      showCancelButton: 0,
      confirmButtonText: 'OK',
    });
  }
  return (
    <div className='container'>
      <div className="container bg-secondary py-5 hero-header mb-5">
        <div className="row py-5">
          <div className="col-12 text-center">
            <h1 className="display-3 text-header animated zoomIn">Contact Us</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-16 col-lg-16 col-xxl-12 col-offset-2 col-xl-12 col-xl-offset-2">
          <div className="gt-panel ">
            <div className="gt-panel-border-orange">
              <section className="section_all" id="about">
                <div className="row vertical_content_manage">
                  <div className="col-lg-12">
                    <div className="about_header_main">
                      <div className="about_icon_box">
                        <p className="text_custom">Kongu Mangai Matrimony Services</p>

                        <div className="row contact-container">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="Your Name"
                                onChange={e => setName(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Your Email"
                                onChange={e => setEmail(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                placeholder="Your Phone"
                                onChange={e => setMobileNumber(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input type="text"
                                name="subject"
                                id="subject"
                                className="form-control"
                                placeholder="Your Subject"
                                onChange={e => setSubject(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols="30"
                                rows="6"
                                placeholder="Your Message"
                                onChange={e => setMessage(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <button type="submit" className="btn-contact-submit" onClick={() => contactFunction()}>Send Message <span></span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-info-wrapper bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h6 className="sub-title">Find Us</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact-info-content">
                <h5>Call Us</h5>
                <p><a href="tel:9384900177">93849 00177</a></p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact-info-content">
                <h5>Email Us</h5>
                <p><a href="mailto:demo@example.com">info@kongumangaimatrimony.com</a></p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact-info-content">
                <h5>Address</h5>
                <p>Modakurichi - Erode - 638001</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contactus